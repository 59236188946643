<template>
  <div class="p-4">
    <b-field label="제목">
      <b-input
        v-model="rename"
        placeholder="제목을 입력해주세요"
        required
        validation-message="제목을 입력해주세요"
      ></b-input>
    </b-field>
    <froala :config="config" v-model="model"></froala>

    <b-button class="mt-2 ml-auto" @click="onSave">저장</b-button>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import client from '../api/client';
import { useRouter } from '../use-router';
import { DialogProgrammatic as Dialog } from 'buefy';

export default defineComponent({
  name: 'Admin',
  setup() {
    const router = useRouter();
    const category = ref('');
    const title = ref('');
    const rename = ref('');

    const model = ref('');
    const config = computed(() => {
      return {
        language: 'ko',
        height: 500,
        dragInline: false,
        imageResize: false,
        toolbarButtons: {
          moreText: {
            // List of buttons used in the  group.
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],

            // Alignment of the group in the toolbar.
            align: 'left',

            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
            buttonsVisible: 4,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignRight',
              'formatOLSimple',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
            align: 'left',
            buttonsVisible: 4,
          },
          moreRich: {
            buttons: ['insertImage', 'insertLink', 'fontAwesome', 'specialCharacters'],
            align: 'left',
            buttonsVisible: 2,
          },
        },
        imageDefaultAlign: 'center',
        imageDefaultWidth: 0,
        imageUploadParams: {
          category: category.value,
        },
        imageUploadURL: 'https://themono.co.kr/api/image_upload',
        imageUploadMethod: 'POST',
        imageMaxSize: 1024 * 1024 * 100,
      };
    });

    async function onSave() {
      await client.edit(category.value, title.value, model.value, rename.value);

      Dialog.alert(`${category.value}의 ${title.value}를 수정했습니다.`);
    }

    onMounted(async () => {
      category.value = router.currentRoute.params.category;
      title.value = router.currentRoute.params.title;
      rename.value = title.value;

      if (category.value === 'projects') {
        category.value = `projects/${router.currentRoute.params.projectCategory}`;
      }

      const result = await client.getDetail(category.value, title.value);

      model.value = result.data.html;
    });

    return {
      title,
      category,
      rename,

      model,
      config,

      onSave,
    };
  },
});
</script>
